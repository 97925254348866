import {
  reactive,
} from '@vue/composition-api';

const ALERT_BAR_DEFAULTS = Object.freeze({
  appendSlotBtns: [],
  defaultSlotText: '',
  dismissText: 'Dismiss',
  type: 'success',
  open: false,
});

let ALERT_BAR = null;

const ALERT_OVERLAY_DEFAULTS = Object.freeze({
  acceptCallback: null,
  acceptText: 'Agree',
  color: 'primary',
  dismissText: 'Dismiss',
  icon: '',
  message: '',
  title: '',
  open: false,
});

let ALERT_OVERLAY = null;

const SNACK_BAR_DEFAULTS = Object.freeze({
  actionSlotBtns: [],
  defaultSlotText: '',
  color: undefined,
  icon: undefined,
  multiLine: false,
  open: false,
  timeout: 5000,
  vertical: false,
});

let SNACK_BAR = null;

// we should be able to move away from this concept (getter function)
// when we move to vue 3. the current problem is jest throws an error
// when using any composition-api functions in the global scope.
// so we can't do const ALERT_BAR = reactive({...}) at the top of the file
function getAlertBar() {
  if (null === ALERT_BAR) {
    ALERT_BAR = reactive({
      ...ALERT_BAR_DEFAULTS,
    });
  }
  return ALERT_BAR;
}

function getAlertOverlay() {
  if (null === ALERT_OVERLAY) {
    ALERT_OVERLAY = reactive({
      ...ALERT_OVERLAY_DEFAULTS,
    });
  }
  return ALERT_OVERLAY;
}

function getSnackBar() {
  if (null === SNACK_BAR) {
    SNACK_BAR = reactive({
      ...SNACK_BAR_DEFAULTS,
    });
  }
  return SNACK_BAR;
}

function onAcceptOverlay(val) {
  if ('function' === typeof ALERT_OVERLAY?.acceptCallback) {
    ALERT_OVERLAY.acceptCallback(val);
  }
}

function alertBarSetup() {
  return {
    alertBar: getAlertBar(),
  };
}

function alertOverlaySetup() {
  return {
    alertOverlay: getAlertOverlay(),
    onAccept: onAcceptOverlay,
  };
}

function snackBarSetup() {
  return {
    snackBar: getSnackBar(),
  };
}

function showAlertBar({
  appendSlotBtns = ALERT_BAR_DEFAULTS.appendSlotBtns,
  defaultSlotText = ALERT_BAR_DEFAULTS.defaultSlotText,
  dismissText = ALERT_BAR_DEFAULTS.dismissText,
  type = ALERT_BAR_DEFAULTS.type,
} = {}) {
  const alertBar = getAlertBar();

  alertBar.appendSlotBtns = appendSlotBtns;
  alertBar.defaultSlotText = defaultSlotText;
  alertBar.dismissText = dismissText;
  alertBar.type = type;
  alertBar.open = true;
}

function showAlertOverlay({
  acceptCallback = ALERT_OVERLAY_DEFAULTS.acceptCallback,
  acceptText = ALERT_OVERLAY_DEFAULTS.acceptText,
  color = ALERT_OVERLAY_DEFAULTS.color,
  dismissText = ALERT_OVERLAY_DEFAULTS.dismissText,
  icon = ALERT_OVERLAY_DEFAULTS.icon,
  message = ALERT_OVERLAY_DEFAULTS.message,
  title = ALERT_OVERLAY_DEFAULTS.title,
} = {}) {
  const alertOverlay = getAlertOverlay();

  alertOverlay.acceptCallback = acceptCallback;
  alertOverlay.acceptText = acceptText;
  alertOverlay.color = color;
  alertOverlay.dismissText = dismissText;
  alertOverlay.icon = icon;
  alertOverlay.message = message;
  alertOverlay.title = title;
  alertOverlay.open = true;
}

function showSnackBar({
  actionSlotBtns = SNACK_BAR_DEFAULTS.actionSlotBtns,
  defaultSlotText = SNACK_BAR_DEFAULTS.defaultSlotText,
  color = SNACK_BAR_DEFAULTS.color,
  icon = SNACK_BAR_DEFAULTS.icon,
  multiLine = SNACK_BAR_DEFAULTS.multiLine,
  timeout = SNACK_BAR_DEFAULTS.timeout,
  vertical = SNACK_BAR_DEFAULTS.vertical,
} = {}) {
  const snackBar = getSnackBar();

  snackBar.actionSlotBtns = actionSlotBtns;
  snackBar.defaultSlotText = defaultSlotText;
  snackBar.color = color;
  snackBar.icon = icon;
  snackBar.multiLine = multiLine;
  snackBar.open = true;
  snackBar.timeout = timeout;
  snackBar.vertical = vertical;
}

export {
  alertBarSetup,
  alertOverlaySetup,
  snackBarSetup,
  showAlertBar,
  showAlertOverlay,
  showSnackBar,
};
