import {
  getCurrentInstance,
  reactive,
} from '@vue/composition-api';

let currentRoute = null;

function useRouter() {
  const {
    proxy,
  } = getCurrentInstance();

  return proxy.$router;
}

function useRoute() {
  if (null !== currentRoute) return currentRoute;

  const router = useRouter();
  const {
    proxy,
  } = getCurrentInstance();

  currentRoute = reactive({
    ...proxy.$route,
  });
  router.afterEach((to) => Object.assign(currentRoute, to));

  return currentRoute;
}

export {
  useRoute,
  useRouter,
};
