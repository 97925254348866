import {
  getModuleNamespace,
} from '@simplesenseio/vuex';

const MODULE_NAMESPACE = getModuleNamespace();
const TITLE_PREFIX = 'Simplesense';

function afterEachPageTitle({
  meta: {
    sNavigationDrawer = {}, title = '',
  } = {},
} = {}) {
  if ('' === title && sNavigationDrawer.label) title = sNavigationDrawer.label;

  let pageTitle = TITLE_PREFIX;

  if (title.length) {
    pageTitle += ` | ${ title }`;
  }

  document.title = pageTitle;
}

async function beforeEachGroups(router, to, from, next) {
  const socketState = router.app.$store.state[MODULE_NAMESPACE.IROC_SOCKET_CLIENT];

  while (false === socketState.connected) {
    await new Promise((resolve) => setTimeout(resolve, 200)); // eslint-disable-line no-promise-executor-return
  }

  const userGroups = router.app.$store.state[MODULE_NAMESPACE.COGNITO_USER].groups;

  if (Array.isArray(userGroups) && 1 === userGroups.length && 'dashboard' === to.name) {
    // eslint-disable-next-line n/callback-return
    next({
      name: 'new-user',
    });
  } else if (to.meta && Array.isArray(to.meta.groups)) {
    if (to.meta.groups.every((group) => userGroups.includes(group))) {
      // eslint-disable-next-line n/callback-return
      next();
    } else {
      // eslint-disable-next-line n/callback-return
      next({
        name: '403',
      });
    }
  } else {
    // eslint-disable-next-line n/callback-return
    next();
  }
}

export {
  afterEachPageTitle,
  beforeEachGroups,
};
