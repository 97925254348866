import {
  vuexComposableFactory,
} from '@simplesenseio/vuex';
import {
  watch,
} from '@vue/composition-api';

const LOADER = {
  icon: 'fa-solid fa-plug',
  indeterminate: true,
  message: 'Establishing connection...',
  visible: true,
};

let DEBOUNCE_TIMER = null;

function setup() {
  const {
    actions: {
      hide,
      setState: show,
    },
    state: {
      icon,
      message,
    },
  } = vuexComposableFactory('LOADER');
  const {
    state: {
      connected: tendonConnected,
    },
  } = vuexComposableFactory('TENDON_SOCKET_CLIENT');
  const {
    state: {
      connected: irocConnected,
    },
  } = vuexComposableFactory('IROC_SOCKET_CLIENT');

  watch([ irocConnected, tendonConnected ], () => {
    const val = (Boolean(irocConnected) && Boolean(tendonConnected));

    clearTimeout(DEBOUNCE_TIMER);
    if (true === val && icon.value === LOADER.icon && message.value === LOADER.message) {
      hide();
    } else if (false === val) {
      DEBOUNCE_TIMER = setTimeout(() => show(LOADER), 500);
    }
  }, {
    immediate: true,
  });
}

export default setup;
