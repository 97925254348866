<template>
  <v-snackbar
    v-model="snackBar.open"
    app
    bottom
    :center="isMobile"
    :color="snackBar.color"
    :max-width="maxWidth"
    :multi-line="snackBar.multiLine"
    :right="right"
    :timeout="snackBar.timeout"
    :vertical="snackBar.vertical"
  >
    <div class="d-flex">
      <v-icon class="mr-4">{{ icon }}</v-icon>
      <div>{{ snackBar.defaultSlotText }}</div>
    </div>
    <template #action>
      <v-btn
        v-for="(item, index) in snackBar.actionSlotBtns"
        :key="`snack-bar-${index}`"
        class="ml-2"
        :text="!item.color"
        :color="item.color"
        outlined
        @click="item.onClick()"
      >
        {{ item.text }}
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
import {
  computed,
} from '@vue/composition-api';
import {
  pluginRefs,
} from '@simplesenseio/design-system/src/composables/vuetify';

import {
  snackBarSetup,
} from '@/composables/alert.js';

export default {
  setup() {
    const {
      snackBar,
    } = snackBarSetup();
    const {
      isMobile,
    } = pluginRefs();
    const icon = computed(() => {
      if (snackBar.icon) return snackBar.icon;
      switch (snackBar.color) {
        case 'success':
          return 'fa-solid fa-circle-check';
        case 'info':
          return 'fa-solid fa-circle-info';
        case 'warning':
          return 'fa-solid fa-exclamation';
        case 'error':
          return 'fa-solid fa-triangle-exclamation';
        default:
          return 'fa-solid fa-comment';
      }
    });
    const maxWidth = computed(() => {
      return isMobile.value ? '90vw' : '500px';
    });
    const right = computed(() => {
      return !isMobile.value;
    });

    return {
      icon,
      isMobile,
      maxWidth,
      right,
      snackBar,
    };
  },
};
</script>
