<template>
  <v-app class="gradient-bg-app">
    <v-main
      :class="{'gradient-bg-app__gradient--animate': !!animate, 'gradient-bg-app__gradient--dark-theme': !!isDark}"
      class="gradient-bg-app__gradient"
    >
      <div class="gradient-bg-app__container fill-height">
        <slot />
      </div>
    </v-main>
  </v-app>
</template>
<script>

import {
  pluginRefs,
} from '@simplesenseio/design-system/src/composables/vuetify';

import './GradientBgApp.scss';
import componentProps from './GradientBgApp.props.js';

export default {
  props: componentProps,
  setup() {
    const {
      isDark,
    } = pluginRefs();

    return {
      isDark,
    };
  },
};
</script>
