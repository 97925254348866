/* eslint-disable no-console */
import {
  register,
} from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  register(`${ process.env.BASE_URL }service-worker.js`, {
    ready() {
      console.log('App is being served from cache by a service worker.\n'
        + 'For more details, visit https://goo.gl/AFskqB');
    },
    registered() {
      console.log('Service worker has been registered.');
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    updated(detail) {
      console.log('New content is available; please refresh.');
      // eslint-disable-next-line n/no-unsupported-features/node-builtins
      document.dispatchEvent(new CustomEvent('service-worker-updated', {
        detail,
      }));
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
/* eslint-enable no-console */
