import {
  dataSourceCrud,
  dataSourceHealthCrud,
  dataSourceReportCrud,
  dodBuildingCrud,
  dodFacilityCrud,
  geofenceCrud,
  groupCrud,
  groupGeofenceReportCrud,
  groupGeofenceSubscriptionCrud,
  macsecSwchIos,
  organizationCrud,
  otDeviceCrud,
  otDeviceSsmParameterCrud,
  otDeviceTypeCrud,
  pingCrud,
  ssmDocumentCrud,
  ssmInstanceCrud,
  ssmParameterCrud,
  userCrud,
  vendorCrud,
} from '@simplesenseio/vuex/modules';
import {
  getModuleNamespace,
  vuexMergeStores,
} from '@simplesenseio/vuex';

function findItemNameIndex(arr, item) {
  const itemName = (item && item.name) ? String(item.name).toLocaleLowerCase() : '';

  return arr.findIndex(({
    name,
  }) => String(name).toLocaleLowerCase() === itemName);
}

function sortItemNameIndex(arr, item) {
  const itemName = (item && item.name) ? String(item.name).toLocaleLowerCase() : '';

  return arr.findIndex(({
    name,
  }) => String(name).toLocaleLowerCase() > itemName);
}

const MODULE_NAMESPACE = getModuleNamespace();
const crudModules = Object.entries({
  iroc: {
    [MODULE_NAMESPACE.DOD_BUILDING_CRUD]: dodBuildingCrud,
    [MODULE_NAMESPACE.DOD_FACILITY_CRUD]: dodFacilityCrud,
    [MODULE_NAMESPACE.MACSEC_SWCH_IOS]: macsecSwchIos,
    [MODULE_NAMESPACE.OT_DEVICE_CRUD]: otDeviceCrud,
    [MODULE_NAMESPACE.OT_DEVICE_SSM_PARAMETER_CRUD]: otDeviceSsmParameterCrud,
    [MODULE_NAMESPACE.OT_DEVICE_TYPE_CRUD]: otDeviceTypeCrud,
    [MODULE_NAMESPACE.PING_CRUD]: pingCrud,
    [MODULE_NAMESPACE.SSM_DOCUMENT_CRUD]: ssmDocumentCrud,
    [MODULE_NAMESPACE.SSM_INSTANCE_CRUD]: ssmInstanceCrud,
    [MODULE_NAMESPACE.SSM_PARAMETER_CRUD]: ssmParameterCrud,
    [MODULE_NAMESPACE.VENDOR_CRUD]: vendorCrud,
  },
  tendon: {
    [MODULE_NAMESPACE.DATA_SOURCE_CRUD]: dataSourceCrud,
    [MODULE_NAMESPACE.DATA_SOURCE_HEALTH_CRUD]: dataSourceHealthCrud,
    [MODULE_NAMESPACE.DATA_SOURCE_REPORT_CRUD]: dataSourceReportCrud,
    [MODULE_NAMESPACE.GEOFENCE_CRUD]: geofenceCrud,
    [MODULE_NAMESPACE.GROUP_CRUD]: groupCrud,
    [MODULE_NAMESPACE.GROUP_GEOFENCE_REPORT_CRUD]: groupGeofenceReportCrud,
    [MODULE_NAMESPACE.GROUP_GEOFENCE_SUBSCRIPTION_CRUD]: groupGeofenceSubscriptionCrud,
    [MODULE_NAMESPACE.ORGANIZATION_CRUD]: organizationCrud,
    [MODULE_NAMESPACE.USER_CRUD]: userCrud,
  },
}).reduce((obj, [ webSocketName, items ]) => {
  const sortByName = [
    MODULE_NAMESPACE.DATA_SOURCE_CRUD,
    MODULE_NAMESPACE.DOD_FACILITY_CRUD,
    MODULE_NAMESPACE.GEOFENCE_CRUD,
    MODULE_NAMESPACE.GROUP_CRUD,
    MODULE_NAMESPACE.ORGANIZATION_CRUD,
    MODULE_NAMESPACE.OT_DEVICE_TYPE_CRUD,
    MODULE_NAMESPACE.USER_CRUD,
    MODULE_NAMESPACE.VENDOR_CRUD,
  ];

  for (const [ ns, store ] of Object.entries(items)) {
    const storeOverride = {
      state: () => ({
        webSocketName,
      }),
    };

    if (sortByName.includes(ns)) {
      storeOverride.getters = {
        __findItem() {
          return findItemNameIndex;
        },

        __sortItem() {
          return sortItemNameIndex;
        },
      };
    }

    obj[ns] = vuexMergeStores(store, storeOverride);
  }
  return obj;
}, {});

export default crudModules;
