const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/DashboardView/DashboardView.vue'),
    meta: {
      sNavigationDrawer: {
        label: 'Dashboard',
        icon: 'fa-solid fa-gauge',
      },
    },
  },
  {
    path: '/iroc',
    component: () => import(/* webpackChunkName: "iroc" */ '@/views/RouteProxyView/RouteProxyView.vue'),
    meta: {
      sNavigationDrawer: {
        label: 'IROC',
        icon: 'fa-solid fa-jet-fighter-up',
      },
      groups: ['iroc-read'],
    },
    children: [
      {
        path: '/iroc/dod-facility/:id?',
        name: 'dod-facility',
        component: () => import(/* webpackChunkName: "dod-facility" */ '@/views/DodFacilityView/DodFacilityView.vue'),
        meta: {
          sNavigationDrawer: {
            label: 'DoD Facilities',
          },
          groups: ['iroc-read'],
        },
      },
      {
        path: '/iroc/dod-building/:id?',
        name: 'dod-building',
        component: () => import(/* webpackChunkName: "dod-building" */ '@/views/DodBuildingView/DodBuildingView.vue'),
        meta: {
          sNavigationDrawer: {
            label: 'DoD Buildings',
          },
          groups: ['iroc-read'],
        },
      },
      {
        path: '/iroc/ot-device-type/:id?',
        name: 'ot-device-type',
        component: () => import(/* webpackChunkName: "ot-device-type" */ '@/views/OtDeviceTypeView/OtDeviceTypeView.vue'),
        meta: {
          sNavigationDrawer: {
            label: 'OT Device Types',
          },
          groups: ['iroc-read'],
        },
      },
      {
        path: '/iroc/ot-device/:id?',
        name: 'ot-device',
        component: () => import(/* webpackChunkName: "ot-device" */ '@/views/OtDeviceView/OtDeviceView.vue'),
        meta: {
          sNavigationDrawer: {
            label: 'OT Devices',
          },
          groups: ['iroc-read'],
        },
      },
      {
        path: '/iroc/ot-device-automation/:id?',
        name: 'ot-device-automation',
        component: () => import(/* webpackChunkName: "ot-device-automation" */ '@/views/OtDeviceAutomationView/OtDeviceAutomationView.vue'),
        meta: {
          sNavigationDrawer: {
            label: 'OT Device Automation',
          },
          groups: [ 'iroc-read', 'iroc-ssm-send-command' ],
        },
      },
      {
        path: '/iroc/vendor/:id?',
        name: 'vendor',
        component: () => import(/* webpackChunkName: "vendor" */ '@/views/VendorView/VendorView.vue'),
        meta: {
          sNavigationDrawer: {
            label: 'Vendors',
          },
          groups: ['iroc-read'],
        },
      },
    ],
  },
  {
    path: '/incident-feed',
    component: () => import(/* webpackChunkName: "incident-feed" */ '@/views/RouteProxyView/RouteProxyView.vue'),
    meta: {
      sNavigationDrawer: {
        label: 'Incident Feed',
        icon: 'fa-solid fa-bell',
      },
      groups: ['tendon-read'],
    },
    children: [
      {
        path: '/incident-feed/reports/:type?/:id?/:startDate?/:endDate?',
        name: 'reports',
        component: () => import(/* webpackChunkName: "reports" */ '@/views/ReportsView/ReportsView.vue'),
        meta: {
          sNavigationDrawer: {
            label: 'Reports',
            // icon: 'fa-solid fa-file-contract',
          },
          groups: ['tendon-read'],
        },
      },
      {
        path: '/incident-feed/data-source/:id?',
        name: 'data-source',
        component: () => import(/* webpackChunkName: "data-source" */ '@/views/DataSourceView/DataSourceView.vue'),
        meta: {
          sNavigationDrawer: {
            label: 'Data Sources',
          },
          groups: ['tendon-read'],
        },
      },
      {
        path: '/incident-feed/organization/:id?',
        name: 'organization',
        component: () => import(/* webpackChunkName: "organization" */ '@/views/OrganizationView/OrganizationView.vue'),
        meta: {
          sNavigationDrawer: {
            label: 'Organizations',
          },
          groups: ['tendon-read'],
        },
      },
      {
        path: '/incident-feed/group/:id?',
        name: 'group',
        component: () => import(/* webpackChunkName: "groups" */ '@/views/GroupView/GroupView.vue'),
        meta: {
          sNavigationDrawer: {
            label: 'Groups',
          },
          groups: ['tendon-read'],
        },
      },
      {
        path: '/incident-feed/user/:id?',
        name: 'user',
        component: () => import(/* webpackChunkName: "user" */ '@/views/UserView/UserView.vue'),
        meta: {
          sNavigationDrawer: {
            label: 'Users',
          },
          groups: ['tendon-read'],
        },
      },
      {
        path: '/incident-feed/geofence/:id?',
        name: 'geofence',
        component: () => import(/* webpackChunkName: "geofence" */ '@/views/GeofenceView/GeofenceView.vue'),
        meta: {
          sNavigationDrawer: {
            label: 'Geofences',
          },
          groups: ['tendon-read'],
        },
      },
      {
        path: '/incident-feed/geofence-subscription/:id?',
        name: 'geofence-subscription',
        component: () => import(/* webpackChunkName: "geofence-subscription" */ '@/views/GroupGeofenceSubscriptionView/GroupGeofenceSubscriptionView.vue'),
        meta: {
          sNavigationDrawer: {
            label: 'Geofence Subscriptions',
          },
          groups: ['tendon-read'],
        },
      },
    ],
  },
  {
    path: '/documents',
    name: 'documents',
    component: () => import(/* webpackChunkName: "documents" */ '@/views/DocumentsView/DocumentsView.vue'),
    meta: {
      sNavigationDrawer: {
        label: 'Documents',
        icon: 'fa-solid fa-file-export',
      },
    },
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: () => import(/* webpackChunkName: "feedback" */ '@/views/FeedbackView/FeedbackView.vue'),
    meta: {
      sNavigationDrawer: {
        label: 'Feedback',
        icon: 'fa-solid fa-comments',
      },
    },
  },
  {
    path: '/changelog',
    name: 'changelog',
    component: () => import(/* webpackChunkName: "changelog" */ '@/views/ChangelogView/ChangelogView.vue'),
    meta: {
      sNavigationDrawer: {
        label: 'Changelog',
        icon: 'fa-solid fa-rocket',
      },
    },
  },
  {
    path: '/unauthorized',
    name: '403',
    component: () => import(/* webpackChunkName: "403" */ '@/views/HttpStatusView/HttpStatusView.vue'),
    props: {
      code: '403',
      icon: 'fa-solid fa-skull',
      message: 'Permission Denied',
    },
  },
  {
    path: '/new-user',
    name: 'new-user',
    component: () => import(/* webpackChunkName: "new-user" */ '@/views/NoGroupsView/NoGroupsView.vue'),
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '@/views/HttpStatusView/HttpStatusView.vue'),
    props: {
      code: '404',
      icon: 'fa-regular fa-face-frown-open',
      message: 'Page Not Found',
    },
  },
];

if ('development' === process.env.VUE_APP_SI_NODE_ENV) {
  routes.push({
    path: '/api-debug',
    name: 'api-debug',
    component: () => import(/* webpackChunkName: "api-debug" */ '@/views/ApiDebugView/ApiDebugView.vue'),
    meta: {
      sNavigationDrawer: {
        label: 'API Debug',
        icon: 'fa-solid fa-plug',
      },
    },
  });
}

export default routes;
