import {
  exampleGenerator,
} from '@simplesenseio/design-system/src/utils/props';

const props = {
  icon: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    default: null,
  },
};

const examples = exampleGenerator({
  alternateObj: {
    icon: 'fa-test',
    title: 'Test Title',
  },
  props,
});

export default props;
export {
  examples,
};
