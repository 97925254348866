import Vue from 'vue';
import * as api from '@vue/composition-api';
import {
  configure,
} from '@simplesenseio/vuex';

const VueCompositionAPI = api.default;

configure('composition-api', api);
Vue.use(VueCompositionAPI);
