import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import {
  Resize,
  Ripple,
  Scroll,
} from 'vuetify/lib/directives';
import options from '@simplesenseio/design-system/lib/vuetify-options';

if (!options.theme?.options) options.theme.options = {};
options.theme.options.cspNonce = process.env.VUE_APP_CSP_STYLE_NONCE;

Vue.use(Vuetify, {
  directives: {
    Resize,
    Ripple,
    Scroll,
  }, // vuetify seems to have a bug loading it's own directive
});
const vuetify = new Vuetify(options);

export default vuetify;
