<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      floating
      temporary
      overlay-opacity="0.8"
      app
      width="320"
    >
      <template #prepend>
        <v-app-bar
          color="transparent"
          elevation="0"
        >
          <v-toolbar-title>
            <s-logo
              size="30"
              class="mb-n2 ml-11"
            />
          </v-toolbar-title>
        </v-app-bar>
      </template>

      <icon-loader
        v-if="loading"
        title="Loading..."
        icon="fa-brands fa-space-awesome"
      />

      <s-navigation-drawer
        v-else
        :items="navItems"
        :value="activeRoute"
        @update:value="onNavUpdateValue"
      />

      <template #append>
        <v-divider />
        <span class="text-overline d-block pl-2">Version {{ appVersion }}</span>
      </template>
    </v-navigation-drawer>

    <v-app-bar
      elevation="0"
      app
    >
      <v-app-bar-nav-icon
        class="no-print"
        @click="drawer = !drawer"
      />

      <v-toolbar-title>
        <s-logo
          size="30"
          class="mb-n2"
        />
      </v-toolbar-title>

      <v-spacer />

      <profile-menu
        name="User's Name"
        email="user@simplesense.io"
      />
    </v-app-bar>

    <v-main>
      <alert-bar />
      <router-view />
    </v-main>

    <snack-bar />
    <alert-overlay />
    <app-loader />
  </v-app>
</template>

<script>

import {
  computed,
  ref,
  unref,
} from '@vue/composition-api';
import {
  router as navRouterComposable,
} from '@simplesenseio/design-system/src/composables/s-navigation-drawer';
import {
  deepCopy,
} from '@simplesenseio/design-system/src/utils/object';
import {
  vuexComposableFactory,
} from '@simplesenseio/vuex';

import {
  redirectAfterLogin,
} from '@/composables/login-path-redirect.js';
import {
  setup as serviceWorkerSetup,
} from '@/composables/service-worker.js';
import AlertBar from '@/components/AlertBar/AlertBar.vue';
import AlertOverlay from '@/components/AlertOverlay/AlertOverlay.vue';
import AppLoader from '@/components/AppLoader/AppLoader.vue';
import IconLoader from '@/components/IconLoader/IconLoader.vue';
import ProfileMenu from '@/components/ProfileMenu/ProfileMenu.vue';
import SnackBar from '@/components/SnackBar/SnackBar.vue';

function filterNavItems(items, groups) {
  const newItems = [];

  for (const i of items) {
    const item = deepCopy(i);

    if (Array.isArray(item.items)) item.items = filterNavItems(item.items, groups);

    if (item.meta && Array.isArray(item.meta.groups)) {
      if (item.meta.groups.every((group) => groups.includes(group))) newItems.push(item);
    } else {
      newItems.push(item);
    }
  }
  return newItems;
}

export default {
  components: {
    AlertBar,
    AlertOverlay,
    AppLoader,
    IconLoader,
    ProfileMenu,
    SnackBar,
  },
  setup(props, ctx) {
    const appVersion = computed(() => process.env.VUE_APP_VERSION);
    const drawer = ref(false);
    const {
      activeRoute,
      items: allNavItems,
      onUpdateValue: onNavUpdateValue,
    } = navRouterComposable(ctx);
    const {
      state: {
        loading,
      },
    } = vuexComposableFactory('IROC_SOCKET_CLIENT');
    const {
      state: {
        groups,
      },
    } = vuexComposableFactory('COGNITO_USER');
    const navItems = computed(() => {
      if (true === loading) return [];
      return filterNavItems(unref(allNavItems), unref(groups));
    });

    redirectAfterLogin();
    serviceWorkerSetup();

    return {
      activeRoute,
      appVersion,
      drawer,
      loading,
      navItems,
      onNavUpdateValue,
    };
  },
};
</script>
