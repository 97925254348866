<template>
  <v-menu
    bottom
    min-width="200px"
    rounded
    offset-y
  >
    <template #activator="{ on }">
      <v-btn
        icon
        class="no-print"
        v-on="on"
      >
        <v-avatar
          size="40"
          color="primary"
        >
          <img
            v-if="null !== avatar"
            referrerpolicy="no-referrer"
            :alt="`${ firstName } ${ lastName }`"
            :src="avatar"
          >
          <v-icon v-else>fa-solid fa-user-astronaut</v-icon>
        </v-avatar>
      </v-btn>
    </template>
    <v-card>
      <v-list-item-content class="justify-center">
        <div class="mx-auto text-center">
          <h3>{{ firstName }} {{ lastName }}</h3>
          <p class="text-caption mt-1">{{ email }}</p>
          <v-divider class="my-3" />
          <div class="px-2 d-flex">
            <v-btn
              depressed
              rounded
              text
              class="flex-grow-1"
              @click="onClick"
            >
              Log Out
            </v-btn>
          </div>
        </div>
      </v-list-item-content>
    </v-card>
  </v-menu>
</template>
<script>
import {
  vuexComposableFactory,
} from '@simplesenseio/vuex';

export default {
  setup() {
    const {
      actions: {
        logOut,
      },
    } = vuexComposableFactory('COGNITO_FEDERATED_AUTH');
    const {
      state: {
        avatar,
        email,
        firstName,
        lastName,
      },
    } = vuexComposableFactory('COGNITO_USER');
    const onClick = () => {
      logOut();
    };

    return {
      avatar,
      email,
      firstName,
      lastName,
      onClick,
    };
  },
};
</script>
