import Vue from 'vue';
import Vuex, {
  Store,
} from 'vuex';
import incidentFeedPlugin, {
  getModuleNamespace,
} from '@simplesenseio/vuex';
import {
  cognitoFederatedAuth,
  cognitoUser,
  loader,
} from '@simplesenseio/vuex/modules';

import crudModules from './modules/crud-factory.js';
import socketClientModules from './modules/socket-client-factory.js';

Vue.use(Vuex);

const MODULE_NAMESPACE = getModuleNamespace();
const config = {
  plugins: [incidentFeedPlugin],
  state: () => ({}),
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    [MODULE_NAMESPACE.COGNITO_FEDERATED_AUTH]: cognitoFederatedAuth,
    [MODULE_NAMESPACE.COGNITO_USER]: cognitoUser,
    [MODULE_NAMESPACE.LOADER]: loader,
    ...socketClientModules,
    ...crudModules,
  },
};
const store = new Store(config);

export default store;
export {
  config,
};
