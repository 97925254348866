import Vue from 'vue';
import VueRouter from 'vue-router';

import {
  afterEachPageTitle,
  beforeEachGroups,
} from './navigation-guard.js';
import routes from './routes.js';

Vue.use(VueRouter);
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.afterEach(afterEachPageTitle);
router.beforeEach(async(to, from, next) => await beforeEachGroups(router, to, from, next));

export default router;
