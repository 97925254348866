<template>
  <transition name="app-transform">
    <loader-view v-if="loading" />
    <log-in-view v-else-if="!authenticated" />
    <app-view v-else />
  </transition>
</template>

<script>
import {
  onMounted,
  ref,
  watch,
} from '@vue/composition-api';
import {
  autoTheme,
  pluginRefs,
} from '@simplesenseio/design-system/src/composables/vuetify';
import {
  vuexComposableFactory,
} from '@simplesenseio/vuex';

import AppView from '@/views/AppView/AppView.vue';
import LoaderView from '@/views/LoaderView/LoaderView.vue';
import LogInView from '@/views/LogInView/LogInView.vue';

export default {
  components: {
    AppView,
    LoaderView,
    LogInView,
  },
  setup() {
    const {
      state: {
        authenticated,
      },
    } = vuexComposableFactory('COGNITO_FEDERATED_AUTH');
    const loading = ref(true);
    const {
      isDark,
    } = pluginRefs();

    watch(isDark, (val) => {
      document.body.classList.remove('theme--dark');
      document.body.classList.remove('theme--light');

      if (val) {
        document.body.classList.add('theme--dark');
      } else {
        document.body.classList.add('theme--light');
      }
    });

    onMounted(() => {
      setTimeout(() => (loading.value = false), 1000);
    });
    autoTheme();

    return {
      authenticated,
      loading,
    };
  },
};
</script>
