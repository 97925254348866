<template>
  <gradient-bg-app
    class="log-in-view"
    animate
  >
    <div class="d-flex flex-column justify-center fill-height log-in-view__container">
      <div class="d-flex justify-center">
        <s-form-sso-log-in
          :providers="[ 'Google' ]"
          class="align-center"
          @submit="onSubmit"
        >
          <template #prepend>
            <s-logo
              size="64"
              class="mb-4"
            />
          </template>
        </s-form-sso-log-in>
      </div>
    </div>
  </gradient-bg-app>
</template>
<script>
import './LogInView.scss';
import {
  vuexComposableFactory,
} from '@simplesenseio/vuex';

import {
  setLoginRedirect,
} from '@/composables/login-path-redirect.js';
import GradientBgApp from '@/components/GradientBgApp/GradientBgApp.vue';

export default {
  components: {
    GradientBgApp,
  },
  setup() {
    const {
      actions: {
        logIn,
      },
    } = vuexComposableFactory('COGNITO_FEDERATED_AUTH');
    const onSubmit = ({
      provider,
    }) => {
      logIn({
        provider,
      });
    };

    setLoginRedirect();

    return {
      onSubmit,
    };
  },
};
</script>
