<template>
  <div class="d-flex icon-loader">
    <div class="align-self-center flex-grow-1 text-center">
      <div class="icon-loader__progress-icon">
        <v-progress-circular
          class="icon-loader__progress"
          :size="55"
          :width="3"
          color="primary"
          indeterminate
        />
        <v-icon
          v-if="!!icon"
          class="icon-loader__icon"
          color="primary"
        >
          {{ icon }}
        </v-icon>
      </div>
      <span
        v-if="!!title"
        class="text-caption text-uppercase primary--text"
        color="primary"
      >{{ title }}</span>
    </div>
  </div>
</template>
<script>
import componentProps from './IconLoader.props.js';
import './IconLoader.scss';

export default {
  props: componentProps,
};
</script>
