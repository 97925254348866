import {
  useRouter,
} from './router.js';

const KEY = 'login-path-redirect';

function redirectAfterLogin() {
  const {
    localStorage, // eslint-disable-line n/no-unsupported-features/node-builtins
  } = window;
  const path = localStorage.getItem(KEY);
  const router = useRouter();

  if (path) {
    localStorage.removeItem(KEY);
    router.push({
      path,
      replace: true,
    });
  }
}

function setLoginRedirect() {
  const {
    localStorage, // eslint-disable-line n/no-unsupported-features/node-builtins
    location: {
      pathname,
    },
  } = window;

  if ('/' !== pathname) {
    localStorage.setItem(KEY, pathname);
  }
}

export {
  redirectAfterLogin,
  setLoginRedirect,
};
