import {
  onMounted,
  onUnmounted,
} from '@vue/composition-api';

import {
  showSnackBar,
  snackBarSetup,
} from './alert.js';

const VERSION = process.env.VUE_APP_VERSION || '1.0.0';

function setup() {
  const {
    snackBar,
  } = snackBarSetup();
  const onServiceWorkerUpdated = () => {
    removeListener(); // eslint-disable-line no-use-before-define
    showSnackBar({
      actionSlotBtns: [
        {
          text: 'Refresh',
          color: 'primary',
          onClick: () => {
            window.location.reload();
          },
        },
        {
          text: 'Dismiss',
          onClick: () => {
            snackBar.open = false;
          },
        },
      ],
      defaultSlotText: `A new version (${ VERSION }) of the application is ready. Click refresh to update to the latest version now or simply refresh the page later to receive the update.`,
      icon: 'fa-solid fa-rocket',
      multiLine: true,
      timeout: -1,
      vertical: true,
    });
  };
  let listening = false;
  const removeListener = () => {
    if (true === listening) {
      document.removeEventListener('service-worker-updated', onServiceWorkerUpdated);
      listening = false;
    }
  };

  onMounted(() => {
    if (false === listening) {
      listening = true;
      document.addEventListener('service-worker-updated', onServiceWorkerUpdated);
    }
  });

  onUnmounted(() => {
    removeListener();
  });

  return {};
}

export {
  setup,
};
