import {
  exampleGenerator,
} from '@simplesenseio/design-system/src/utils/props';

const props = {
  animate: {
    type: Boolean,
    default: false,
  },
};

const examples = exampleGenerator({
  props,
});

export default props;
export {
  examples,
};
