import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"bottom":"","min-width":"200px","rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"no-print",attrs:{"icon":""}},on),[_c(VAvatar,{attrs:{"size":"40","color":"primary"}},[(null !== _vm.avatar)?_c('img',{attrs:{"referrerpolicy":"no-referrer","alt":(_vm.firstName + " " + _vm.lastName),"src":_vm.avatar}}):_c(VIcon,[_vm._v("fa-solid fa-user-astronaut")])],1)],1)]}}])},[_c(VCard,[_c(VListItemContent,{staticClass:"justify-center"},[_c('div',{staticClass:"mx-auto text-center"},[_c('h3',[_vm._v(_vm._s(_vm.firstName)+" "+_vm._s(_vm.lastName))]),_c('p',{staticClass:"text-caption mt-1"},[_vm._v(_vm._s(_vm.email))]),_c(VDivider,{staticClass:"my-3"}),_c('div',{staticClass:"px-2 d-flex"},[_c(VBtn,{staticClass:"flex-grow-1",attrs:{"depressed":"","rounded":"","text":""},on:{"click":_vm.onClick}},[_vm._v(" Log Out ")])],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }