const {
  VUE_APP_AWS_GOV_REGION,
  VUE_APP_COGNITO_CLIENT_ID,
  VUE_APP_COGNITO_DOMAIN,
  VUE_APP_COGNITO_USERPOOL_ID,
  VUE_APP_IDENTITY_PROVIDER_CLIENT_ID,
} = process.env;

const REDIRECT_URL = `${ window.location.protocol }//${ window.location.hostname }${ (window.location.port !== 80 && window.location.port !== 443 && window.location.port !== '') ? `:${ window.location.port }` : '' }`;

const config = {
  Auth: {
    Cognito: {
      region: VUE_APP_AWS_GOV_REGION,
      userPoolId: VUE_APP_COGNITO_USERPOOL_ID,
      userPoolClientId: VUE_APP_COGNITO_CLIENT_ID,
      loginWith: {
        oauth: {
          domain: VUE_APP_COGNITO_DOMAIN,
          scopes: [
            'aws.cognito.signin.user.admin',
            'email',
            'openid',
            'profile',
          ],
          redirectSignIn: [REDIRECT_URL],
          redirectSignOut: [REDIRECT_URL],
          clientId: VUE_APP_IDENTITY_PROVIDER_CLIENT_ID,
          responseType: 'code',
        },
      },
    },
  },
};

export default config;
