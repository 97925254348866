<template>
  <s-loader
    :icon="icon"
    :indeterminate="indeterminate"
    :message="message"
    :value="value"
    :visible="visible"
  />
</template>
<script>
import {
  vuexComposableFactory,
} from '@simplesenseio/vuex';

import socketLoader from '@/composables/socket-loader.js';

export default {
  setup() {
    const {
      state: {
        icon,
        indeterminate,
        message,
        value,
        visible,
      },
    } = vuexComposableFactory('LOADER');

    socketLoader();

    return {
      icon,
      indeterminate,
      message,
      value,
      visible,
    };
  },
};
</script>
