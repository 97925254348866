import Vue from 'vue';
import './plugins/composition-api.js'; // eslint-disable-line import/no-unassigned-import
import {
  provide,
} from '@vue/composition-api';
import {
  configure,
} from '@simplesenseio/vuex';
import Chart from '@simplesenseio/design-system/lib/chartjs-options';

import AMPLIFY_CONFIG from './amplify/config.js';
import App from './App.vue';
import './plugins/register-service-worker.js'; // eslint-disable-line import/no-unassigned-import
import store from './store/index.js'; // this needs to be imported before router
import router from './router/index.js';
import vuetify from './plugins/vuetify.js';
import 'roboto-fontface/css/roboto/sass/roboto-fontface.scss';
import '@fontsource/manrope'; // eslint-disable-line import/no-unassigned-import
import '@fortawesome/fontawesome-free/css/all.css';
import '@simplesenseio/design-system/src/styles/styles.sass';
import '@/styles/styles.sass';

// eslint-disable-next-line camelcase, no-undef
__webpack_nonce__ = process.env.VUE_APP_CSP_SCRIPT_NONCE;

configure('amplify', AMPLIFY_CONFIG);
configure('vue-util', {
  set: Vue.set,
  delete: Vue.delete,
});
Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
  setup() {
    provide('vuex-store', store); // can remove this when we upgrade to V3
    provide('chart-js', Chart); // can remove this when we upgrade to V3
  },
}).$mount('#app');
