import SLogo from '@simplesenseio/design-system/src/components/SLogo/SLogo.vue'
import SNavigationDrawer from '@simplesenseio/design-system/src/components/SNavigationDrawer/SNavigationDrawer.vue'
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c(VNavigationDrawer,{attrs:{"floating":"","temporary":"","overlay-opacity":"0.8","app":"","width":"320"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c(VAppBar,{attrs:{"color":"transparent","elevation":"0"}},[_c(VToolbarTitle,[_c(SLogo,{staticClass:"mb-n2 ml-11",attrs:{"size":"30"}})],1)],1)]},proxy:true},{key:"append",fn:function(){return [_c(VDivider),_c('span',{staticClass:"text-overline d-block pl-2"},[_vm._v("Version "+_vm._s(_vm.appVersion))])]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[(_vm.loading)?_c('icon-loader',{attrs:{"title":"Loading...","icon":"fa-brands fa-space-awesome"}}):_c(SNavigationDrawer,{attrs:{"items":_vm.navItems,"value":_vm.activeRoute},on:{"update:value":_vm.onNavUpdateValue}})],1),_c(VAppBar,{attrs:{"elevation":"0","app":""}},[_c(VAppBarNavIcon,{staticClass:"no-print",on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c(VToolbarTitle,[_c(SLogo,{staticClass:"mb-n2",attrs:{"size":"30"}})],1),_c(VSpacer),_c('profile-menu',{attrs:{"name":"User's Name","email":"user@simplesense.io"}})],1),_c(VMain,[_c('alert-bar'),_c('router-view')],1),_c('snack-bar'),_c('alert-overlay'),_c('app-loader')],1)}
var staticRenderFns = []

export { render, staticRenderFns }