<template>
  <s-alert
    v-model="alertBar.open"
    :dismiss-txt="alertBar.dismissTxt"
    :type="alertBar.type"
    app
    dense
    transition="s-transition-fade"
  >
    {{ alertBar.defaultSlotText }}
    <template #append>
      <v-btn
        v-for="(item, index) in alertBar.appendSlotBtns"
        :key="`app-view-s-alert-${index}`"
        text
        @click="item.onClick()"
      >
        {{ item.text }}
      </v-btn>
    </template>
  </s-alert>
</template>
<script>
import {
  alertBarSetup,
} from '@/composables/alert.js';

export default {
  setup() {
    const {
      alertBar,
    } = alertBarSetup();

    return {
      alertBar,
    };
  },
};
</script>
