import {
  vuexMergeStores,
  setModuleNamespace,
} from '@simplesenseio/vuex';
import {
  cognitoFederatedSocketClient,
} from '@simplesenseio/vuex/modules';

const {
  VUE_APP_WEBSOCKET_URL_IROC,
  VUE_APP_WEBSOCKET_URL_TNDN,
} = process.env;

const clientModules = [
  {
    nsKey: 'IROC_SOCKET_CLIENT',
    nsVal: 'irocSocketClient',
    name: 'iroc',
    url: VUE_APP_WEBSOCKET_URL_IROC,
  },
  {
    nsKey: 'TENDON_SOCKET_CLIENT',
    nsVal: 'tendonSocketClient',
    name: 'tendon',
    url: VUE_APP_WEBSOCKET_URL_TNDN,
  },
].reduce((obj, {
  nsKey,
  nsVal,
  name,
  url,
}) => {
  setModuleNamespace(nsKey, nsVal);
  obj[nsVal] = vuexMergeStores(cognitoFederatedSocketClient, {
    state: () => ({
      url,
      name,
    }),
  });
  return obj;
}, {});

export default clientModules;
