<template>
  <v-app>
    <s-loader
      icon="fa-solid fa-user-secret"
      indeterminate
      message="Determining User Credentials..."
      transition-name="none"
      visible
    />
  </v-app>
</template>
<script>
export default {};
</script>
