<template>
  <s-alert-overlay
    v-model="alertOverlay.open"
    :accept-text="alertOverlay.acceptText"
    :color="alertOverlay.color"
    :dismiss-text="alertOverlay.dismissText"
    :icon="alertOverlay.icon"
    :title="alertOverlay.title"
    transition-name="s-transition-fade-transform"
    @accept="onAccept"
  >
    {{ alertOverlay.message }}
  </s-alert-overlay>
</template>
<script>
import {
  alertOverlaySetup,
} from '@/composables/alert.js';

export default {
  setup() {
    const {
      alertOverlay,
      onAccept,
    } = alertOverlaySetup();

    return {
      alertOverlay,
      onAccept,
    };
  },
};
</script>
